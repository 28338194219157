import React, {
	ChangeEvent,
	ChangeEventHandler,
	FocusEventHandler,
	forwardRef,
	KeyboardEvent,
	useMemo,
	useState,
} from "react"
import { css } from "@emotion/react"
import { IconButton } from "../buttons"
import { Icon, IconFamily, IconName } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface TextInputProps extends FieldContainerProps {
	type?: "text" | "number" | "password"
	value?: string | null
	onChange?: (newValue: string | null) => void
	onChangeEvent?: ChangeEventHandler<HTMLInputElement>
	onFocus?: FocusEventHandler<HTMLInputElement>
	onBlur?: FocusEventHandler<HTMLInputElement>
	onReturn?: () => void
	placeholder?: string
	icon?: IconName
	iconFamily?: IconFamily
	onIconClick?: (newValue?: string | null) => void
	autoFocus?: boolean
	isLoading?: boolean
	clearable?: boolean
	disabled?: boolean
	maxLength?: number
	disablePasswordToggle?: boolean
	className?: string
	errorStyle?: string
	/**
	 * If user deletes all text in the field, do you want `null` or `""`?
	 *  @default false
	 */
	returnEmptyString?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{
			type = "text",
			value,
			onChange,
			onChangeEvent,
			onFocus,
			onBlur,
			onReturn,
			label,
			placeholder,
			/**	Show an icon in the right side of the input field. */
			icon,
			iconFamily = "regular",
			onIconClick,
			autoFocus,
			isLoading,
			clearable,
			disabled,
			maxLength,
			disablePasswordToggle,
			/** Pass on any additional css you may need. */
			className,
			returnEmptyString = false,
			errorStyle = "",
			...rest
		},
		ref
	) => {
		const [currentType, setCurrentType] = useState(type)

		const showingClearIcon = useMemo(
			() => !!clearable && !!value && !isLoading,
			[clearable, value, isLoading]
		)
		const showingPasswordToggleIcon = type === "password" && !disablePasswordToggle
		const showingAnyIcon = useMemo(
			() => !!icon || showingClearIcon || isLoading || showingPasswordToggleIcon,
			[icon, showingClearIcon, isLoading, showingPasswordToggleIcon]
		)
		const iconIsClickable = useMemo(
			() => (!!onIconClick && !!icon) || showingClearIcon || showingPasswordToggleIcon,
			[onIconClick, showingClearIcon, icon, showingPasswordToggleIcon]
		)

		const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
			e.persist()
			const rawValue = e.target.value
			const newValue =
				rawValue !== "" ? rawValue
				: returnEmptyString ? rawValue
				: null

			if (onChangeEvent) onChangeEvent(e)
			if (onChange) onChange(newValue)
		}

		const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
			if (onReturn && e?.code?.toLowerCase() === "enter") {
				onReturn()
			}
		}

		const handleIconClick = () => {
			if (showingClearIcon && !!onChange) {
				onChange(null)
			} else if (onIconClick) {
				onIconClick(value)
			} else if (showingPasswordToggleIcon) {
				setCurrentType((prev) => {
					if (prev === "password") {
						return "text"
					} else {
						return "password"
					}
				})
			}
		}

		const computedIconContainerStyle = useMemo(() => {
			return css`
				pointer-events: ${iconIsClickable ? undefined : "none"};
				right: ${iconIsClickable ? "0" : "0.65rem"};
				top: ${iconIsClickable ? "0.45rem" : "1rem"};
				&:hover {
					opacity: ${iconIsClickable ? "1" : "0.5"};
				}
				button {
					// The IconButton is a wee bit big for the select.
					transform: scale(0.85);
				}
			`
		}, [iconIsClickable])

		return (
			<FieldContainer label={label} {...rest}>
				<div css={inputContainerStyle}>
					<input
						ref={ref}
						maxLength={maxLength}
						autoFocus={autoFocus}
						type={currentType}
						className={className}
						disabled={disabled}
						css={inputStyle(errorStyle)}
						value={value ?? ""}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={handleTextChange}
						onKeyUp={(e) => (onReturn ? handleKeyUp(e) : undefined)}
						placeholder={placeholder ?? (label ? `${label}...` : undefined)}
					/>
					{showingAnyIcon && (
						<div css={[iconContainerStyle, computedIconContainerStyle]}>
							{isLoading ?
								<Icon icon="spinner-third" spin />
							: showingClearIcon ?
								<IconButton icon="times" title="Clear" onClick={handleIconClick} />
							: iconIsClickable && !!icon ?
								<IconButton icon={icon} onClick={handleIconClick} />
							: showingPasswordToggleIcon ?
								<IconButton
									icon={currentType === "password" ? "eye" : "eye-slash"}
									onClick={handleIconClick}
								/>
							: icon ?
								<Icon icon={icon} family={iconFamily} fontSize={1} />
							:	null}
						</div>
					)}
				</div>
			</FieldContainer>
		)
	}
)

const inputContainerStyle = css`
	position: relative;
	width: inherit;
`
const iconContainerStyle = css`
	position: absolute;
	opacity: 0.5;
	font-size: 1rem;
`
const inputStyle = (errorStyle: string) => css`
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid ${errorStyle ? "#E02104" : "#6b7280"};
`
