import React, { useMemo } from "react"
import { css, SerializedStyles } from "@emotion/react"
import { Skeleton } from "@material-ui/lab"
import { Box, BoxProps } from "../layout"
import ComingSoon from "../../../../../apps/customer-portal/src/images/parts/coming-soon.svg"
export interface PartImageProps extends BoxProps {
	src?: string | null
	alt?: string | null
	isLoading?: boolean
	imageCss?: SerializedStyles
	skeleton?: {
		size?: number
		containerProps?: BoxProps
	}
	showBorder?: boolean
	objectPosition?: "top" | "right" | "bottom" | "left" | string
}

export const PartImage: React.FC<PartImageProps> = ({
	src,
	alt,
	height,
	minHeight,
	maxHeight,
	width,
	minWidth = "3rem",
	maxWidth,
	objectPosition = "top center",
	display = "block",
	isLoading,
	skeleton = {},
	showBorder,
	imageCss,
	...rest
}) => {
	const style = useMemo(() => {
		return css`
			border: ${showBorder ? "1px solid #eee" : undefined};
			img {
				display: inline;
				border-radius: 4px;
				object-fit: contain;
				object-position: ${objectPosition};
				height: 100%;
				width: 100%;
			}
		`
	}, [objectPosition, showBorder])

	const handleImgErr = (event: React.SyntheticEvent<HTMLImageElement>) => {
		event.currentTarget.onerror = null // prevents looping
		event.currentTarget.src = ComingSoon
	}

	const partImage = () =>
		src ? <img src={src} onError={handleImgErr} /> : <img src={ComingSoon} />

	return (
		<Box
			display={display}
			{...rest}
			css={style}
			height={height}
			minHeight={minHeight}
			maxHeight={maxHeight}
			width={width}
			minWidth={minWidth}
			maxWidth={maxWidth}
		>
			<div css={[imageZoomContainerStyle, imageCss]}>
				{isLoading ?
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						{...skeleton.containerProps}
					>
						<Skeleton
							variant="circle"
							height={skeleton.size ?? 100}
							width={skeleton.size ?? 100}
						/>
					</Box>
				:	partImage()}
			</div>
		</Box>
	)
}

const imageZoomContainerStyle = css`
	overflow: hidden;
	object-fit: cover;
	align-items: center;
	max-width: 30rem;
	margin: 0 auto;
`
