import React, { memo } from "react"

import { css, Theme } from "@emotion/react"

import { extractNumber, formatCurrency } from "@ncs/ts-utils"

import { Box } from "../layout"
import { SkeletonRows } from "../transitions"
import { Heading, HeadingProps } from "./Heading"
import { Paragraph } from "./Paragraph"

interface PriceProps {
	small?: boolean
	/** Provide the final price after any discounts. Will get multiplied by `quantity`.  */
	price: number | string
	/** Optionally pass in a quantity. Price will be multiplied by this. Defaults to 1. */
	quantity?: number
	/** The price before any discounts. */
	nonDiscountedPrice?: number | string
	textAlign?: "left" | "right" | "center"
	isLoading?: boolean
	color?: HeadingProps["color"]
	/**
	 * Round to the nearest integer.
	 */
	round?: boolean
	className?: string
}

export const PDPPriceEcomm: React.FC<PriceProps> = memo(
	({
		small,
		price,
		nonDiscountedPrice,
		quantity = 1,
		textAlign = "left",
		isLoading,
		color,
		round,
		className,
	}) => {
		const hasDiscount = !!(nonDiscountedPrice && nonDiscountedPrice > price)
		const percentageOff =
			hasDiscount ?
				Math.round(
					(1 - parseFloat(String(price)) / parseFloat(String(nonDiscountedPrice))) * 100
				)
			:	0

		return (
			<Box display="flex" flexDirection="row" textAlign={textAlign}>
				{hasDiscount && (
					<Box
						css={css`
							margin-right: 64px;
						`}
					>
						<Heading
							variant={small ? "h3" : "h2"}
							css={finalPriceStyle}
							className={className}
							textAlign={textAlign}
							color={color}
							bold
						>
							{isLoading ?
								<Box
									display="flex"
									alignItems="center"
									justifyContent={
										textAlign === "right" ? "flex-end"
										: textAlign === "left" ?
											"flex-start"
										: textAlign === "center" ?
											"center"
										:	undefined
									}
								>
									$
									<SkeletonRows
										rows={1}
										width={8}
										rowProps={{ my: 0, ml: 0.75, mt: -0.25 }}
									/>
								</Box>
							:	formatCurrency(extractNumber(price) * quantity, round ? 0 : undefined)
							}
						</Heading>
						<div
							css={css`
								color: #374151;
								font-family: "Atlas Grotesk";
								font-size: 16px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
								margin-top: 12px;
							`}
						>
							Your Price ({percentageOff}% Off)
						</div>
						{quantity > 1 && (
							<Paragraph small color="secondary">
								{formatCurrency(price)} each
							</Paragraph>
						)}
					</Box>
				)}

				<Box>
					<Heading
						variant={small ? "h3" : "h2"}
						css={nonDiscountedPriceStyle}
						className={className}
						textAlign={textAlign}
						color={color}
						bold
					>
						{hasDiscount ?
							<span
								css={css`
									text-decoration: line-through;
								`}
							>
								{formatCurrency(nonDiscountedPrice ?? 0)}
							</span>
						:	<span>{formatCurrency(nonDiscountedPrice ?? price)}</span>}
					</Heading>
					<div
						css={css`
							color: #374151;
							font-family: "Atlas Grotesk";
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							margin-top: 12px;
						`}
					>
						List price
					</div>
				</Box>
			</Box>
		)
	}
)

const finalPriceStyle = (theme: Theme) => css`
	color: ${theme.palette.primary.main};
	color: #10a85f;
	font-family: "Atlas Grotesk";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

const nonDiscountedPriceStyle = (theme: Theme) => css`
	color: #111827;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`
