import { z } from "zod"

export type OldLookup<Id = string> = {
	id: Id
	code: string
	description: string | null
}

export interface ShipMethod extends OldLookup {}

export interface ReferenceModel<Id = string> {
	id: Id
	code: string
	description: string | null
}

export type Lookup<Id = string> = {
	id: Id
	code: string
	description: string
}

export const PartMinimalSchema = z.object({
	/** This is the InventoryPart ID. Not the same as an OnlinePart's id. */
	id: z.string(),
	/** Not the same as an OnlinePart's part number. */
	partNumber: z.string(),
	/** This is the all-caps KBM product name. Prefer to use the OnlinePart's title instead. */
	description: z.string(),
	unitOfMeasure: z.string(),
})
export type PartMinimal = z.infer<typeof PartMinimalSchema>
