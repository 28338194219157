import {
	FocusEventHandler,
	MutableRefObject,
	ReactElement,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from "react"

import { css, Theme } from "@emotion/react"
import { CircularProgress, TextField } from "@material-ui/core"
import { Autocomplete, AutocompleteProps } from "@material-ui/lab"
import { useThrottleCallback } from "@react-hook/throttle"
import isEqual from "lodash/isEqual"
import unionWith from "lodash/unionWith"

import { usePrevious } from "../../util"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

import { Search } from "@material-ui/icons"

export interface SearchableSelectProps<Item> extends FieldContainerProps {
	/** The selected item */
	value: Item | null
	options: Item[]
	/** Fires when user selects an option. */
	onItemSelect: (newItem: Item | null) => void
	/** Fires as user types. */
	onInputChange?: (newValue: string | null) => void
	/**
	 * Unique field that we can use to test if items are different from one another and
	 * test which item is selected.
	 *
	 * @default "id"
	 */
	uniqueIdParam?: keyof Item | ((item: Item) => string | number)
	sortingParam?: keyof Item | ((item: Item) => string | number)
	isLoading?: boolean
	disabled?: boolean
	noOptionsText?: string
	placeholder?: string
	onBlur?: FocusEventHandler
	/** Use a magnifying glass instead of the caret down icon? */
	useSearchIcon?: boolean
	/** If the options prop changes, should we keep the previous options and just add them
	 * to a growing list? Useful when you're calling an API with a search query. */
	disableOptionsAggregation?: boolean
	startAdornment?: ReactNode
	autoFocus?: boolean
	inputRef?: MutableRefObject<HTMLDivElement | null>
	disableAutocomplete?: boolean
	/**
	 * How many items should we show at once in the list before hiding the rest?
	 * @default 50
	 */
	maxVisibleOptions?: number | null

	// Pass through a bunch of props from Autocomplete.
	getOptionLabel: AutocompleteProps<Item, false, false, false>["getOptionLabel"]
	getOptionDisabled?: AutocompleteProps<Item, false, false, false>["getOptionDisabled"]
	getOptionSelected?: AutocompleteProps<Item, false, false, false>["getOptionSelected"]
	renderInput?: AutocompleteProps<Item, false, false, false>["renderInput"]
	renderOption?: AutocompleteProps<Item, false, false, false>["renderOption"]
	filterSelectedOptions?: AutocompleteProps<Item, false, false, false>["filterSelectedOptions"]
	filterOptions?: AutocompleteProps<Item, false, false, false>["filterOptions"]
}

/**
 * When you have a component that's wrapping `SearchableSelect`, and that component
 * is extending `SearchableSelectProps`, you'd usually need to omit some props that
 * you don't want exposed when consuming that wrapping component. That's what this is.
 */
export type ExtendableSearchableSelectProps<Item> = Omit<
	SearchableSelectProps<Item>,
	"options" | "onItemSelect" | "onChange" | "getOptionLabel"
> & {
	/**
	 * Fires when user selects an option. Optionally also passes a boolean flag indicating
	 * the onChange was triggered by setting the value from an initial ID.
	 */
	onChange: (newItem: Item | null, isInitialSetting?: boolean) => void
}

export const SearchableSelect = <Item,>({
	value,
	options,
	onItemSelect,
	label,
	onInputChange,
	uniqueIdParam = "id" as keyof Item,
	sortingParam,
	isLoading,
	disabled,
	noOptionsText = "No results",
	placeholder,
	onBlur,
	useSearchIcon,
	disableOptionsAggregation,
	startAdornment,
	getOptionLabel,
	getOptionDisabled,
	getOptionSelected,
	renderInput,
	renderOption,
	filterSelectedOptions,
	filterOptions,
	disableAutocomplete = true,
	autoFocus,
	inputRef,
	maxVisibleOptions = 50,
	...rest
}: SearchableSelectProps<Item>): ReactElement => {
	const [inputValue, setInputValue] = useState<string>("")

	/** As the search input changes, we will throttle-y fire onInputChange. */
	const throttledInputHandler = useThrottleCallback(
		(newInput: string | null) => {
			if (onInputChange) onInputChange(newInput)
		},
		2,
		true
	)

	/** Maintain local state of the options, letting us keep all the options that are
	 * in. Whatever querying is getting results in the parent can pass in lots of things,
	 * and we'll just keep building up this array, and we'll rely on Autocomplete's
	 * text filtering of the the input to provide options to the user. */
	const [aggregateOptions, setAggregateOptions] = useState<Item[]>(options)

	const prevOptions = usePrevious(options)
	useEffect(() => {
		if (!disableOptionsAggregation && !isEqual(options, prevOptions)) {
			setAggregateOptions((prev) => {
				return unionWith(prev, options, (a, b) => {
					const aId =
						typeof uniqueIdParam === "function" ? uniqueIdParam(a) : a[uniqueIdParam]
					const bId =
						typeof uniqueIdParam === "function" ? uniqueIdParam(b) : b[uniqueIdParam]

					return aId === bId
				})
			})
		}
	}, [options, prevOptions, uniqueIdParam, sortingParam, disableOptionsAggregation])

	const optionsWithValue = useMemo(() => {
		const initialOptions = disableOptionsAggregation ? options : aggregateOptions

		if (value == null) return initialOptions

		// What we're getting for value may or may not be in the options if it was
		// stored somewhere like local storage and the page was just refreshed.
		// Add it into our options if it's missing.
		if (
			initialOptions.every((option) => {
				const optionId =
					typeof uniqueIdParam === "function" ?
						uniqueIdParam(option)
					:	option[uniqueIdParam]
				const currentValueId =
					typeof uniqueIdParam === "function" ?
						uniqueIdParam(value)
					:	value[uniqueIdParam]

				return optionId !== currentValueId
			})
		) {
			return [value, ...initialOptions]
		}

		return initialOptions
	}, [aggregateOptions, disableOptionsAggregation, options, value, uniqueIdParam])

	const sortedOptions = useMemo(() => {
		if (sortingParam) {
			return optionsWithValue.sort((a, b) => {
				const aValue =
					typeof sortingParam === "function" ? sortingParam(a) : a[sortingParam]
				const bValue =
					typeof sortingParam === "function" ? sortingParam(b) : b[sortingParam]

				return aValue > bValue ? 1 : -1
			})
		} else {
			return optionsWithValue
		}
	}, [optionsWithValue, sortingParam])

	return (
		<FieldContainer label={label} {...rest}>
			<Autocomplete
				inputValue={inputValue}
				css={autoCompleteStyle}
				value={value}
				options={sortedOptions}
				loading={isLoading}
				loadingText="Searching..."
				getOptionLabel={getOptionLabel}
				noOptionsText={
					value ? ""
					: inputValue ?
						noOptionsText ?? ""
					:	placeholder || ""
				}
				onChange={(e, newItem) => {
					onItemSelect(newItem)
				}}
				onBlur={onBlur}
				getOptionDisabled={getOptionDisabled}
				getOptionSelected={
					getOptionSelected ??
					((option, currentValue) => {
						const optionId =
							typeof uniqueIdParam === "function" ?
								uniqueIdParam(option)
							:	option[uniqueIdParam]
						const currentValueId =
							typeof uniqueIdParam === "function" ?
								uniqueIdParam(currentValue)
							:	currentValue[uniqueIdParam]

						return optionId === currentValueId
					})
				}
				popupIcon={useSearchIcon ? <Search /> : undefined}
				onInputChange={(e, newValue, reason) => {
					setInputValue(newValue)
					if (reason === "input" || reason === "clear") {
						throttledInputHandler(newValue || null)
					}
				}}
				renderOption={renderOption}
				filterSelectedOptions={filterSelectedOptions}
				filterOptions={(selectOptions, selectState) => {
					if (filterOptions) {
						return filterOptions(selectOptions, selectState)
					}

					// If you haven't typed anything yet, only show the options if there aren't that many.
					if (
						maxVisibleOptions !== null &&
						selectOptions.length > maxVisibleOptions &&
						!selectState.inputValue
					) {
						return []
					}

					const matches = []
					const querySegments = selectState.inputValue.split(" ")

					for (const option of selectOptions) {
						const optionLabel = selectState.getOptionLabel(option).toLowerCase()

						if (
							querySegments.every((segment) =>
								optionLabel.includes(segment.toLowerCase())
							)
						) {
							matches.push(option)
						}

						if (matches.length === maxVisibleOptions) {
							break
						}
					}

					return matches
				}}
				renderInput={(params) => {
					if (renderInput) {
						return renderInput(params)
					}

					return (
						<TextField
							{...params}
							variant="outlined"
							autoFocus={autoFocus}
							inputRef={inputRef}
							InputProps={{
								...params.InputProps,
								placeholder: placeholder || `${label}...`,
								startAdornment: startAdornment,
								autoComplete: disableAutocomplete === true ? "off" : undefined,
								endAdornment: (
									<>
										{isLoading ?
											<CircularProgress color="inherit" size={20} />
										:	null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)
				}}
			/>
		</FieldContainer>
	)
}

// These styles mostly just make the Autocomplete input look like the rest of the Legos inputs.
const autoCompleteStyle = (theme: Theme) => css`
	

	.MuiOutlinedInput-root {
		background: white;
		border-radius: 0;
		border-radius: 0.5rem;
		border: 1px solid #6b7280;
		height: 53px;
		
		&.Mui-disabled {
			// Make the MUI disabled style look like the regular input disabled style.
			opacity: 0.5;
			color: ${theme.palette.text.primary};
			background: rgba(239, 239, 239, 0.3);
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: #ddd;
	}
	.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-width: 1px;
	}
	.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
		padding: 5px 0;
	}
	.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
		width: 1.6rem;
		height: 1.6rem;
		fill: #bbb;
	}
	.MuiAutocomplete-popupIndicatorOpen {
		transform: rotate(0);
	}
`
