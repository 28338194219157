import React from "react"

import parsePhoneNumberFromString from "libphonenumber-js"
import Input from "react-phone-number-input/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"
import { TextInput } from "./TextInput"
import { css } from "@emotion/react"
import { Paragraph } from "../../../../web-legos"
import { validatePhone } from "../../../../ts-utils/src"

export interface PhoneInputEcommProps extends FieldContainerProps {
	value?: string | null
	onChange?: (newValue: string | null) => void
	onBlur?: React.FocusEventHandler
	placeholder?: string
	disabled?: boolean
	required?: boolean
	/**
	 * Basically turns this into a regular text field input. Use as an escape hatch
	 * for allowing international phone numbers, which our formatting and validation
	 * isn't currently set up to handle.
	 */
	disableFormattingAndValidation?: boolean
}

export const PhoneInputEcomm: React.FC<PhoneInputEcommProps> = React.memo(
	({
		value,
		onChange,
		onBlur,
		placeholder: placeholderParam,
		label = "Phone",
		disabled,
		required,
		disableFormattingAndValidation,
		...rest
	}) => {
		const handlePhoneChange = (newValue: string | null | undefined) => {
			if (onChange) {
				let result: string | null = newValue ?? null

				// This phone input component we're using does this weird thing where
				// when you delete a phone number entered by hitting the delete key one
				// by one, when it comes down to the first two numbers (value would look
				// like "+18", for example), it then doesn't show anything in UI. That value is
				// still stored though, even though it looks like we should be holding
				// null now. It behaves normally if you select all and hit delete key.
				// Dunno what's up.
				if (result && result.startsWith("+") && result.length <= 3) {
					result = null
				}

				onChange(result)
			}
		}

		const placeholder = placeholderParam ?? (label ? `${label}...` : undefined)
		const isValidNumber = value && !validatePhone(value)

		if (disableFormattingAndValidation) {
			return (
				<FieldContainer label={label} {...rest}>
					<TextInput
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						disabled={disabled}
					/>
				</FieldContainer>
			)
		}

		return (
			<FieldContainer label={label} {...rest}>
				<Input
					css={css`
						height: 60px;
						flex-shrink: 0;
						border-radius: 8px;
						border: 1px solid var(--Neutrals-500, #6b7280);
						padding-left: 1em;
					`}
					country="US"
					defaultCountry="US"
					placeholder={placeholder}
					international={false}
					value={parsePhoneNumberFromString(value ?? "", "US")?.number}
					onChange={handlePhoneChange}
					onBlur={onBlur}
					disabled={disabled}
					style={{ border: isValidNumber ? "1px solid red" : undefined }}
				/>
				{required && !value && (
					<div css={container}>
						<FontAwesomeIcon icon={faCircleExclamation} style={{ color: "red" }} />
						<Paragraph color="error">Please enter a phone number.</Paragraph>
					</div>
				)}
				{isValidNumber && (
					<div css={container}>
						<FontAwesomeIcon icon={faCircleExclamation} style={{ color: "red" }} />
						<Paragraph color="error">Please enter a valid phone number.</Paragraph>
					</div>
				)}
			</FieldContainer>
		)
	}
)

const container = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
	margin-top: 0.8rem;
`
